import React from 'react';
import TopBar from '../Components/TopBar';

/* Images */
import ptitDelire from '../Images/ptitdelire.png';
import logo from '../Images/logo-ptitDelire-white.png';
import world from '../Images/world.svg';

const PtitDelire = (props) => {
    return ( <>
        <div className="ptitDelire container">
            <TopBar back={true}></TopBar>
            <main>
                <div className="mainImg">
                    <img src={ptitDelire} alt=""/>
                    <div className="txt">
                        <div className="left">
                            <img src={logo} alt="" width="96"/>
                            <p>
                                <span className="green">Concept</span><br/>
                                Sneakers, Gaming & Lifestyle …<br/>The Crossover Media between<br/>football players & urban artists.
                            </p>
                        </div>
                        <div className="right">
                            <div className="links">
                                <a href="https://www.instagram.com/greengardenops/" target="_blank" rel="noopener noreferrer">Instagram</a>
                                <a href="https://www.youtube.com/channel/UCOV30hLlzXc4eWVy2HVhN-g" target="_blank" rel="noopener noreferrer">Youtube</a>
                            </div>
                            <img src={world} alt="" width="96"/>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </> );
}
 
export default PtitDelire;