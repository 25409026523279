import React from 'react';
import { Link } from 'react-router-dom';
import MenuMobile from './MenuMobile';

/* Images */
import logo from '../Images/logo-ggd.svg';

const TopBar = ({back, projects, agency}) => {
    
    const handleClick = () => {
        const menuMobile = document.getElementsByClassName('menuMobile');
        menuMobile[0].classList.add('active');
        console.log(menuMobile);
    }
    return ( <>
        
        <MenuMobile></MenuMobile>
        <header className="top-logo">
            {
                (back)?
                <>
                    <div className="left">
                        <Link to="/" className="back-logo">
                            <img src={logo} alt="" width="52" />
                            <p>Green Garden <br/>Digital</p>
                        </Link>
                    </div>
                    <div className="right">
                        <button onClick={handleClick}>Menu</button>
                    </div>
                    <div className="listPages">
                        {   (projects)?
                            <>
                                <Link to="/agency">Agency</Link>
                                <Link to="/ptitdelire">Ptit Delire</Link>
                            </>
                            :(agency)?
                            <>
                                <Link to="/projects">Projects</Link>
                                <Link to="/ptitdelire">Ptit Delire</Link>
                            </>
                            :
                            <>
                                <Link to="/projects">Projects</Link>
                                <Link to="/agency">Agency</Link>
                            </>
                        }
                    </div>
                    <Link className="back" to="/">Back</Link>
                </>:
                <>
                    <div className="left">
                        <img src={logo} alt="" width="52" />
                        <p>Green Garden <br/>Digital</p>
                    </div>
                    <div className="right">
                        <button onClick={handleClick}>Menu</button>
                    </div>
                </>
            }
        </header>
    </> );
}
 
export default TopBar;