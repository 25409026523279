import React from 'react';
import TopBar from '../Components/TopBar';

import '../Scss/Slick.scss';
import Slider from 'react-slick';

/* Images */
import adidas from '../Images/projects/adidas.png';
import ea from '../Images/projects/ea.png';
import fff from '../Images/projects/fff.png';
import nike from '../Images/projects/nike.png';
import nintendo from '../Images/projects/nintendo.png';
import warner from '../Images/projects/warner.png';
import world from '../Images/world.svg';

const Projects = (props) => {
    const settings = {
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        autoplay: true,
        customPaging: function() {
            return (
                <button></button>
            );
        },
    };
    return ( <>
    <div className="project container">
        <TopBar back={true} projects={true}></TopBar>
        <main>
            <Slider {...settings} className='projectsSlider'>
                <div className="project-slide">
                    <img src={ea} alt=""/>
                    <div className="txt">
                        <div className="logoEa"></div>
                        <p>
                            <span className="green">client</span><br/>
                            EA
                        </p>
                        <p>
                            <span className="green">MISSIONS</span><br/>
                            Content Creation and Production,<br/>Influencer Strategy 
                        </p>
                        <a href="lien">view more</a>
                    </div>
                    <div className="right">
                        <div className="cp">
                            <p>©2020<br/>FRANCE</p>
                        </div>
                        <img src={world} alt="" width="96"/>
                    </div>  
                </div>
                <div className="project-slide">
                    <img src={nike} alt=""/>
                    <div className="txt">
                        <div className="logoNike"></div>
                        <p>
                            <span className="green">client</span><br/>
                            Nike
                        </p>
                        <p>
                            <span className="green">MISSIONS</span><br/>
                            Content Production,<br/>Wholesale, Ecorner<br/>and Talent Management
                        </p>
                        <a href="lien">view more</a>
                    </div>
                    <div className="right">
                        <div className="cp">
                            <p>©2020<br/>FRANCE</p>
                        </div>
                        <img src={world} alt="" width="96"/>
                    </div>  
                </div>
                <div className="project-slide">
                    <img src={adidas} alt=""/>
                    <div className="txt">
                        <div className="logoAdidas"></div>
                        <p>
                            <span className="green">client</span><br/>
                            Adidas
                        </p>
                        <p>
                            <span className="green">MISSIONS</span><br/>
                            Content Production,<br/>Wholesale, Ecorner<br/>and Talent Management
                        </p>
                        <a href="lien">view more</a>
                    </div>
                    <div className="right">
                        <div className="cp">
                            <p>©2020<br/>FRANCE</p>
                        </div>
                        <img src={world} alt="" width="96"/>
                    </div>  
                </div>
                <div className="project-slide">
                    <img src={fff} alt=""/>
                    <div className="txt">
                        <div className="logoFff"></div>
                        <p>
                            <span className="green">client</span><br/>
                            FFF
                        </p>
                        <p>
                            <span className="green">MISSIONS</span><br/>
                            Content Production 
                        </p>
                        <a href="lien">view more</a>
                    </div>
                    <div className="right">
                        <div className="cp">
                            <p>©2020<br/>FRANCE</p>
                        </div>
                        <img src={world} alt="" width="96"/>
                    </div>  
                </div>
                <div className="project-slide">
                    <img src={warner} alt=""/>
                    <div className="txt">
                        <div className="logoWarner"></div>
                        <p>
                            <span className="green">client</span><br/>
                            warner<br/>bros
                        </p>
                        <p>
                            <span className="green">MISSIONS</span><br/>
                            Content Production,<br/>Display, Influencer Strategy<br/>and Talent Management

                        </p>
                        <a href="lien">view more</a>
                    </div>
                    <div className="right">
                        <div className="cp">
                            <p>©2020<br/>FRANCE</p>
                        </div>
                        <img src={world} alt="" width="96"/>
                    </div>  
                </div>
                <div className="project-slide">
                    <img src={nintendo} alt=""/>
                    <div className="txt">
                        <div className="logoNintendo"></div>
                        <p>
                            <span className="green">client</span><br/>
                            nintendo
                        </p>
                        <p>
                            <span className="green">MISSIONS</span><br/>
                            Display Master EMEA
                        </p>
                        <a href="lien">view more</a>
                    </div>
                    <div className="right">
                        <div className="cp">
                            <p>©2020<br/>FRANCE</p>
                        </div>
                        <img src={world} alt="" width="96"/>
                    </div>  
                </div>
            </Slider>
        </main>
    </div>
    </> );
}
 
export default Projects;