import React from 'react';
import './Scss/App.scss';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Home from './Pages/Home';
import Projects from './Pages/Projects';
import Agency from './Pages/Agency';
import PtitDelire from './Pages/PtitDelire';

function App() {
  return (
    <>
    <div className="App">
      <HashRouter>
        <Switch>
          <Route path="/ptitdelire" component={PtitDelire} />
          <Route path="/agency" component={Agency} />
          <Route path="/projects" component={Projects} />
          <Route path="/" component={Home} />
        </Switch>
      </HashRouter>
    </div>
    </>
  );
}

export default App;
