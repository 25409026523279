import React from 'react';
import { Link } from 'react-router-dom';

/* Images */
import logo from '../Images/logo-ggd.svg';

const MenuMobile = ({className}) => {
    const handleClick = () => {
        const menuMobile = document.getElementsByClassName('menuMobile');
        menuMobile[0].classList.remove('active');
    }
    return ( <>
    
        <div className={"menuMobile " + (className?"active":"")}>
            <div className="top-logo">
                <div className="left">
                    <Link to="/" className="back-logo">
                        <img src={logo} alt="" width="52" />
                        <p>Green Garden <br/>Digital</p>
                    </Link>
                </div>
                <div className="right">
                    <button onClick={handleClick}>Back</button>
                </div>
            </div>
            <div className="listPages">
                <Link to="/projects" onClick={handleClick}>Projects</Link>
                <Link to="/agency" onClick={handleClick}>Agency</Link>
                <Link to="/ptitdelire" onClick={handleClick}>Ptit Delire</Link>
            </div>
            <div className="rs">
                <a href="https://www.instagram.com/greengardenops/" target="_blank" rel="noopener noreferrer">Instagram</a>
                <a href="https://www.linkedin.com/company/green-garden-digital/" target="_blank" rel="noopener noreferrer">Linkedin</a>
                <a href="mailto:contact@greengardendigital.com" target="_blank" rel="noopener noreferrer">Contact</a>
            </div>
        </div>
    
    </> );
}
 
export default MenuMobile;