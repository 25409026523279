import React, { useState } from 'react';
import TopBar from '../Components/TopBar';

import '../Scss/Slick.scss';
import Slider from 'react-slick';

/* Images */
import hash from '../Images/agency/1.png';
import terry from '../Images/agency/2.png';
import maxime from '../Images/agency/3.png';
import karl from '../Images/agency/4.png';
import jeremie from '../Images/agency/5.png';
import julia from '../Images/agency/6.png';
import johan from '../Images/agency/7.png';
import jerome from '../Images/agency/8.png';
import celia from '../Images/agency/9.png';
import melvil from '../Images/agency/10.png';
import dan from '../Images/agency/11.png';
import salah from '../Images/agency/12.png';
import morgan from '../Images/agency/13.png';
import charlotte from '../Images/agency/14.png';
import etienne from '../Images/agency/15.png';

const Agency = (props) => {

    const [paramSlide,setParamSlide] = useState({
        activeSlide: 0
    });
    const settings = {
        dots: false,
        speed: 500,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        fade: false,
        autoplay: false,
        beforeChange: (current, next) => setParamSlide({ activeSlide: next }),
            
    };
    return ( <>

        <div className="agency container">
            <TopBar back={true} agency={true}></TopBar>
            <main>
                <div className="content desktop" id="content">
                    <div className="hash">
                        <img src={hash} alt="" width="250" />
                    </div>
                    <div className="terry">
                        <img src={terry} alt="" width="250" />
                    </div>
                    <div className="maxime">
                        <img src={maxime} alt="" width="250" />
                    </div>
                    <div className="karl">
                        <img src={karl} alt="" width="250" />
                    </div>
                    <div className="jeremie">
                        <img src={jeremie} alt="" width="250" />
                    </div>
                    <div className="julia">
                        <img src={julia} alt="" width="250" />
                    </div>
                    <div className="johan">
                        <img src={johan} alt="" width="250" />
                    </div>
                    <div className="etienne">
                        <img src={etienne} alt="" width="250" />
                    </div>
                    <div className="jerome">
                        <img src={jerome} alt="" width="250" />
                    </div>
                    <div className="celia">
                        <img src={celia} alt="" width="250" />
                    </div>
                    <div className="melvil">
                        <img src={melvil} alt="" width="250" />
                    </div>
                    <div className="dan">
                        <img src={dan} alt="" width="250" />
                    </div>
                    <div className="salah">
                        <img src={salah} alt="" width="250" />
                    </div>
                    <div className="morgan">
                        <img src={morgan} alt="" width="250" />
                    </div>
                    <div className="charlotte">
                        <img src={charlotte} alt="" width="250" />
                    </div>
                </div>
                <div className="content mobile" id="content">
                    <Slider {...settings} className='agencySlider'>
                        <div className="slide">
                            <div className="hash">
                                <img src={hash} alt="" width="250" />
                            </div>
                            <div className="terry">
                                <img src={terry} alt="" width="250" />
                            </div>
                            <div className="jerome">
                                <img src={jerome} alt="" width="250" />
                            </div>
                            <div className="celia">
                                <img src={celia} alt="" width="250" />
                            </div>
                        </div>
                        <div className="slide">
                            <div className="maxime">
                                <img src={maxime} alt="" width="250" />
                            </div>
                            <div className="karl">
                                    <img src={karl} alt="" width="250" />
                            </div>
                            <div className="jeremie">
                                <img src={jeremie} alt="" width="250" />
                            </div>
                            <div className="julia">
                                <img src={julia} alt="" width="250" />
                            </div>
                        </div>
                        <div className="slide">
                            <div className="melvil">
                                <img src={melvil} alt="" width="250" />
                            </div>
                            <div className="dan">
                                <img src={dan} alt="" width="250" />
                            </div>
                            <div className="salah">
                                <img src={salah} alt="" width="250" />
                            </div>
                            <div className="morgan">
                                <img src={morgan} alt="" width="250" />
                            </div>
                        </div>
                        <div className="slide">
                            <div className="johan">
                                <img src={johan} alt="" width="250" />
                            </div>
                            <div className="etienne">
                                <img src={etienne} alt="" width="250" />
                            </div>
                            <div className="charlotte">
                                <img src={charlotte} alt="" width="250" />
                            </div>
                        </div>
                    </Slider>
                    <p>{paramSlide.activeSlide + 1} / <span className="green">4</span></p>
                </div>
            </main>
        </div>

    </> );
}
 
export default Agency;