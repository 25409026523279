import React from 'react';
import { Link } from 'react-router-dom';
import TopBar from '../Components/TopBar';

/* Images */
import world from '../Images/world.svg';

const Home = (props) => {
    return ( <> 
    <div className="home container">
        <TopBar back={false}></TopBar>
        
        <main className="content">
            <h1><span className="green">GGD</span> is a creative <br/> digital agency.</h1>
            <p className="subTitle"><span className="green">SPORT</span> . <span className="green">LIFESTYLE</span> . <span className="green">ENTERTAINMENT</span></p>
            <p className="subTitle2">Content production, activation, display & drive to estore.</p>

            <div className="listPages">
                <Link to="/projects">Projects</Link>
                <Link to="/agency">Agency</Link>
                <Link to="/ptitdelire">Ptit Delire</Link>
            </div>
        </main>

        <footer className="footer">
            <div className="copyright">
                <img src={world} alt=""/>
                <p>©2021<br/>FRANCE</p>
            </div>
            <div className="rs">
                <p>GGD</p>
                <div>
                    <a href="https://www.instagram.com/greengardenops/" target="_blank" rel="noopener noreferrer">Instagram</a>
                    <a href="https://www.linkedin.com/company/green-garden-digital/" target="_blank" rel="noopener noreferrer">Linkedin</a>
                    <a href="mailto:contact@greengardendigital.com" target="_blank" rel="noopener noreferrer">Contact</a>
                </div>
            </div>
        </footer>

    </div> 
    </> );
}
 
export default Home;